
import '../index.css';
import ConfigIcon from '../icon/ConfigIcon';
import {Link} from "react-router-dom";
import { AiFillPlaySquare } from "react-icons/ai";
function Home() {
    return(
      <div class="container" style={{marginTop:'10%'}}>
      <div class="blackLine"></div>
      <h1 class="whiteText" style={{textAlign: 'center', marginTop: '50px'}}>Büro für Architektur und Energieberatung</h1>
      <div class="blackLine" style={{marginTop:'50px'}}></div>
      <div class="center" id="playButtonIcon">
        <ConfigIcon>
          <Link to="/navigation" ><AiFillPlaySquare style={{color:"rgb(246 120 40)"}} onClick={test} /></Link>
        </ConfigIcon>
      </div>
    </div>
    );
}

export default Home;

function test() {

}


