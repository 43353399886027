import NavigationModule from "./NavigationModule";

function Leistungen() {
    return(
        <div>
            <NavigationModule site={{url:'/leistungen'}}/>
            <div class="blackLine" style={{marginTop: "20vh",}}></div>
            <div class="scrollTextBox">
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div class="fieldContainer">
                    Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen.
                    </div>
                    <div class="fieldContainer">
                    Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen.
                    </div>
                </div>
            </div>
            <div class="blackLine"></div>
        </div>
        
    );
}

export default Leistungen;