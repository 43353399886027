import {Link} from "react-router-dom";
import NavigationModule from "./NavigationModule";
import Background1 from "../images/test1.jpg";
import Background2 from "../images/test2.jpg";

function Projekte(props) {
    return(
        
        <div>
            <NavigationModule site={{url:'/projekte'}}/>
            <div class="blackLine" style={{marginTop: "20vh",}}></div>
            <div class="scrollTextBox">
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap"}}>
                <Link to="/projekt/1" state={{url: ["test1.jpg", "test2.jpg", "test3.jpg"]}}  style={{ textDecoration: 'none', color: "black"}}><div class="projektObject" style={{ backgroundImage: `url(${Background1})`}} ><h4>Projekt1</h4></div></Link>
                <Link to="/projekt/2" state={{url: ["test1.jpg", "test2.jpg", "test3.jpg"]}}  style={{ textDecoration: 'none', color: "black"}}><div class="projektObject" style={{ backgroundImage: `url(${Background2})`}}><h4>Projekt2</h4></div></Link>
                    <div class="projektObject"></div>
                    <div class="projektObject"> </div>
                    <div class="projektObject"> </div>
                    <div class="projektObject"> </div>
                    <div class="projektObject"> </div>
                    <div class="projektObject"> </div>
                    <div class="projektObject"> </div>
                    <div class="projektObject"> </div>
                    <div class="projektObject"> </div>
                    <div class="projektObject"> </div>

                </div>
            </div>
            <div class="blackLine"></div>
        </div>
        
    );
}

export default Projekte;