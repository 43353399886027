import NavigationModule from "./NavigationModule";
import Person from "../images/person.jpg";

function Buero() {
    return(
        <div class="mainContainer">
            <NavigationModule site={{url:'/buero'}}/>
            <div class="blackLine" style={{marginTop: "20vh",}}></div>
            <div class="scrollTextBox">
                <div class="containerFlex" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div class="fieldContainer" >
                    Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen.
                    </div>
                    <div class="fieldContainer" style={{ display: "flex", justifyContent:"center", alignItems: "center", overflow:"hidden"}}><img src={Person}  alt="image" style={{ width: '240px', border: "2px solid black"}}/></div>
                </div>
            </div>
            <div class="blackLine"></div>
        </div>
        
    );
}

export default Buero;