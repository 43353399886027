
import NavigationModule from "./NavigationModule";
import '../index.css';

function Rechtliches() {
    return(
        <div>
            <NavigationModule site={{url:'/rechtliches'}}/>

            <div class="blackLine" style={{marginTop: "20vh",}}></div>
                <div class="scrollTextBox">
                    <div style={{marginLeft: "30px", marginRight: "30px"}}>
                        <h4> Datenschutzerklärung</h4>
                        <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TMG). In dieser Datenschutzerklärung informieren wir Sie darüber, wie wir Ihre personenbezogenen Daten im Rahmen dieser Website verarbeiten.</p>
                        <h5>Erhebung und Verarbeitung personenbezogener Daten</h5>
                        <p>Unsere Website verwendet keine Cookies und keine Tracking-Tools. Es werden keinerlei personenbezogene Daten erhoben, verarbeitet oder gespeichert. Wir setzen keine Analysetools ein und es findet kein Profiling statt.</p>
                        <h5>Ihre Rechte</h5>
                        <p>Sie haben das Recht auf Auskunft über die von uns verarbeiteten personenbezogenen Daten, auf Berichtigung oder Löschung, auf Einschränkung der Verarbeitung, auf Widerspruch gegen die Verarbeitung sowie auf Datenübertragbarkeit gemäß den Bestimmungen der DSGVO. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren.</p>
                        <h5>Kontakt</h5>
                        <p>Wenn Sie Fragen zur Verarbeitung Ihrer personenbezogenen Daten haben, können Sie uns gerne kontaktieren:</p>
                        <p>[EMAIL]</p>
                        <p>Diese Datenschutzerklärung wird regelmäßig aktualisiert, um den rechtlichen Anforderungen zu entsprechen und Änderungen unserer Dienstleistungen abzubilden.</p>
                        <p>Stand: 18.06.2024</p>
                        <h5> Impressum</h5>
                        


                    </div>
                </div>
            <div class="blackLine"></div>

        </div>
    );
}

export default Rechtliches;