import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Navigation from './pages/Navigation';
import NotFoundPage from './pages/NotFoundPage';
import Buero from './pages/Buero';
import Projekte from './pages/Projekte';
import Leistungen from './pages/Leistungen';
import Rechtliches from './pages/Rechtliches';
import 'bootstrap/dist/css/bootstrap.min.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from "./pages/Home";
import ProjektSeite from './pages/Projekt';

const router = createBrowserRouter([{
  path: '/',
  element: <Home/>,
  errorElement: <NotFoundPage/>
}, 
{
  path: '/navigation',
  element: <Navigation/>
},
{
  path: '/buero',
  element: <Buero/>
},
{
  path: '/leistungen',
  element: <Leistungen/>
}, {
  path: '/rechtliches',
  element: <Rechtliches/>
},
{
  path: '/projekte',
  element: <Projekte/>
},
{
  path: '/projekt',
  element: <ProjektSeite/>
},
{
  path: '/projekt/:projektId',
  element: <ProjektSeite/>
}
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <RouterProvider router={router}/>
    
    
  </React.StrictMode>
);







// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
