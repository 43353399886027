import {redirect, useLocation} from "react-router-dom";
import NavigationModule from "./NavigationModule";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";

let urlIndex = 0;
function Projekt(props) {
    const images = useLocation().state?.url;
    console.log(images);
    var url = images;



    const [currentImage, setCurrentImage] = useState(url[0]);
    const [openImage, setOpenImage] = useState("none");

    const setImage = (urlI) => {
        urlIndex = urlI;
        setCurrentImage("../img/" + url[urlIndex]);
    }

    const openImages = (state) => {
        setOpenImage(state);
    }
    const changeImage = (change) => {
        urlIndex += change;
        if(urlIndex < 0) urlIndex = url.length-1;
        if(urlIndex > url.length-1) urlIndex = 0;
        setImage(urlIndex);
    }    

    useEffect(()=>{
        setImage(0);
    }, []) 

    return(
        <div>
            <NavigationModule site={{url:'/projekte'}}/>
            <div class="blackLine" style={{marginTop: "20vh",}}></div>
            <div class="scrollTextBox" style={{minHeight: "300px"}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div class="fieldContainer" style={{minHeight: "300px"}}>
                    Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen. Hier könnte Ihr Text stehen.
                    </div>
                    <div class="fieldContainer" style={{}}>
                        <div class="imageDisplay" style={{position:"relative"}}>
                            <img src={currentImage} class="imageStyle" alt="image" onClick={() => openImages("block")}></img>
                            <div class="imageSlider">
                                <Button className="imageScrollButton" onClick={() => setImage(0)}></Button>
                                <Button className="imageScrollButton" onClick={() => setImage(1)}></Button>
                                <Button className="imageScrollButton" onClick={() => setImage(2)}></Button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="blackLine"></div>
            <div class="expandedImage"  style={{display: openImage, width: "65vw", height: "65vh", backgroundColor: "white", position: "absolute", top: "15vh", left: "20vw"}}>
                <div class="imageStyle" style={{width: "100%", height: "100%", objectFit: "contain", backgroundSize: "cover", backgroundImage: `url(${currentImage})`}}>
                    <IoIosCloseCircle onClick={() => openImages("none")} style={{position: "absolute" ,right: "10px", top: "10px", borderRadius:"100%", width: "50px", height: "50px", fontSize:"30px", color:"white"}}></IoIosCloseCircle>
                    <FaAngleLeft onClick={() => changeImage(-1)} style={{position:"absolute", left: "30px", top:"45%", width:"75px", height: "75px", color: "white"}}></FaAngleLeft>
                    <FaAngleRight onClick={() => changeImage(1)} style={{position:"absolute", right: "30px", top:"45%", width:"75px", height: "75px", color: "white"}}></FaAngleRight>
                </div>
                <div style={{width:"100%", border:"2px solid black", borderTop: "none", background: "white", textAlign: "center"}}><h3>Hier kann die Bildbeschreibung stehen</h3></div>
            </div>
        </div>
    );
}



export default Projekt;


