
import '../index.css';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

function Navigation() {
    return(
        <div>
            {/*<NavigationModule site={{url:''}}/>*/}
            <h2 style={{textAlign: "right", marginRight: "30px" ,marginTop: "10px"}}>BAE</h2>
            <div class="navigationDiv">
                    <div><Link to="/buero"><Button variant="primary"><h4>Büro</h4></Button></Link></div>
                    <div><Link to="/projekte"><Button variant="primary"><h4>Projekte</h4></Button></Link></div>
                    <div><Link to="/leistungen"><Button variant="primary"><h4>Leistungen</h4></Button></Link></div>
                    <div><Link to="/rechtliches"><Button variant="primary"><h4>Rechtliches</h4></Button></Link></div>
            </div>
        </div>

    );
}

export default Navigation;