import {Link} from "react-router-dom";
function NotFoundPage() {
    return(
        <div>
            <div>404 Not Found</div>
            <Link to="/"><h1>Home</h1></Link>
        </div>
        
    );
}

export default NotFoundPage;