
import { useEffect, useRef, useState  } from 'react';
import '../index.css';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

function NavigationModule({site}) {
    useEffect(() => {
        if(site.url == 'b1uero') {
            setWidth('500px');
        }
    });
    const buero = useRef(null);
    const [width, setWidth] = useState();
    
    return(
        <div className="nav">
            <div class="navigationTop" style={{marginTop:'10px', width: width}}>
                    <div id="buero" ref={buero}><Link to="/buero"><Button variant="primary"><h4 style={{ textDecoration: site.url === '/buero' ? 'underline' : 'none' }}>Büro</h4></Button></Link></div>
                    <div id="projekte"><Link to="/projekte"><Button variant="primary"><h4 style={{ textDecoration: site.url === '/projekte' ? 'underline' : 'none' }}>Projekte</h4></Button></Link></div>
                    <div id="leistung"><Link to="/leistungen"><Button variant="primary"><h4 style={{ textDecoration: site.url === '/leistungen' ? 'underline' : 'none' }}>Leistungen</h4></Button></Link></div>
                    <div id="rechtliches"><Link to="/rechtliches"><Button variant="primary"><h4 style={{ textDecoration: site.url === '/rechtliches' ? 'underline' : 'none' }}>Rechtliches</h4></Button></Link></div>
            <h2 style={{position:"absolute", right:"40px", color: "white"}}>BAE</h2>
                
            </div>
        </div>

    );

}

export default NavigationModule;